import React from 'react'
import Helmet from 'react-helmet'
import {StaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/Layout'
import TitleBanner from '../components/TitleBanner'
import {Container} from '../components/Theme'

// Placeholders
import slide1Image from '../../static/assets/phone.jpg'

const NewsListContainer = styled(Container)`
  display: grid;
  grid-gap: 5rem;
  grid-template-columns: 1fr;

  @media(min-width:750px){
    grid-template-columns: 1fr 1fr;
  }
`;


const NEWS_LIST_QUERY = graphql`
query newsListQuery {
  allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "news"}}},
    sort: {fields: frontmatter___date, order: DESC}
    ) {
    edges {
      node {
        excerpt(pruneLength: 300)
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
}
`;

const NewsPage = () => (

  <StaticQuery
    query={NEWS_LIST_QUERY}
    render={data => (
      <Layout>
        <Helmet
          title={'Metro Communications News'}
          meta={[
            {name: `description`, content: `The Latest News from Metro Communications`},
            {name: `keywords`, content: ``},
          ]}
        />
        <TitleBanner
          image={slide1Image}
          title={`News & Blog`}
        />
        <NewsListContainer>
          {data.allMarkdownRemark.edges.map(({node})=>(
            <article key={node.id}>
              <Link to={`/news${node.fields.slug}`}><h3>{node.frontmatter.title}</h3></Link>
              <p>{node.frontmatter.date}</p>
              <p>{node.excerpt}</p>
              <Link to={`/news${node.fields.slug}`}>Read More</Link>
            </article>
          ))}

        </NewsListContainer>

      </Layout>
    )}
  />
)

export default NewsPage;